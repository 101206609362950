import { Flex, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Appbar = () => {
  return (
    <Flex h={16} w="full" px={[4]} justifyContent="space-between" alignItems="center" maxWidth="8xl" mx="auto">
      <div>GBMSF</div>

      <Stack direction="row" spacing={10}>
        <Link to="/">Home</Link>
        <Link to="/">About</Link>
        <Link to="/">Gallery</Link>
        <Link to="/">Contact</Link>
      </Stack>
    </Flex>
  );
};

export default Appbar;
