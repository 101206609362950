import React from "react";
import { Link } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";

const Homepage = () => {
  const [image, setImage] = React.useState(1);

  React.useEffect(() => {
    let timer = setTimeout(() => {
      let next = image + 1;
      if (next > 7) next = 1;

      setImage(next);
    }, 5000);

    return () => clearTimeout(timer);
  }, [image]);

  return (
    <Box
      flexGrow={1}
      display="flex"
      position="relative"
      backgroundColor="red"
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(/images/${image}.jpg)`}
    >
      <Box zIndex={1} w="full" h="100%" backgroundColor="#000" opacity="0.6" position="absolute" top={0}></Box>

      <Box
        zIndex={2}
        flexGrow={1}
        color="#fff"
        display="flex"
        textAlign="center"
        alignItems="center"
        position="relative"
        justifyContent="center"
      >
        <div>
          <Heading fontSize={[84]}>Under Construction</Heading>
          <Heading fontSize={[84]}>Here</Heading>
          {false && <Link to="/dev">development</Link>}
        </div>
      </Box>
    </Box>
  );
};

export default Homepage;
