import PageLayout from "layout/page";
import Homepage from "pages/homepage";
import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  { path: "/", element: <PageLayout />, children: [{ path: "/", element: <Homepage /> }] },
  { path: "/dev", element: <h1>Development in progress</h1> },
]);

export default router;
