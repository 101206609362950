const Footer = () => {
  return (
    <div>
      footer
      <div>footer</div>
    </div>
  );
};

export default Footer;
