import Appbar from "./appbar";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import { Flex, Stack } from "@chakra-ui/react";

const PageLayout = () => {
  return (
    <Stack w="full" minH="100%" spacing={0}>
      <Appbar />

      <Flex minHeight="calc(100vh - 64px)" flexGrow={1} flexDirection="column" w="full">
        <Outlet />
      </Flex>

      <Footer />
    </Stack>
  );
};

export default PageLayout;
