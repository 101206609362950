import router from "./app/router";
import theme from "app/theme/chakra";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router}></RouterProvider>
    </ChakraProvider>
  );
};

export default App;
